export const webURLs = {
    SINGLE_USERDETAILS: "/api/v2/user/",
    LOGIN_DOCTOR: "/api/v1/user/doctor-login",
    LOGIN_RECEPTION: "/api/v1/user/reception-login",
    // Appointments -->
    PATIENTS: "/api/v2/patient",
    ADD_PATIENT: "/api/v2/patient/add",
    UPDATE_PATIENT: "/api/v2/patient/update",
    DELETE_PATIENT: "/api/v2/patient/delete",
    EDIT_PATIENT_INFO: "/api/v2/patient/edit",
    UPLOAD_PATIENT_PHOTO: "/api/v2/patient/upload-image/", // :patient_id
    // Appointments -->
    // APPOINTMENTS: "/v2/customer/getall",
    ADD_NEW_APPOINTMENT: "/api/v2/appointment/add",
    GET_APPOINTMENT: "/api/v2/appointment",
    GET_SELECTED_PATIENT_APPOINTMENT: "/api/v2/appointment?patient_name=",
    DELETE_APPOINTMENT: "/api/v2/appointment/delete",
    UPDATE_APPOINTMENT: "/api/v2/appointment/update/",
    UPDATE_APPOINTMENT_FIELDS: "/api/v2/appointment/updatefield/",
    // slots API -->
    MORNING_SLOTS: "api/v2/slot/get?day=",
    ALL_MORNING_SLOTS: "api/v2/slot/getAllSlot",
    UPDATE_MORNING_SLOTS: "api/v2/slot/update/",
    EVENING_SLOTS: "api/v2/eveningSlot/get?day=",
    ALL_EVENING_SLOTS: "api/v2/eveningSlot/getAllSlot",
    UPDATE_EVENING_SLOTS: "api/v2/eveningSlot/updateevening/",

    //patient bill
    ADD_BILL: "api/v2/patient/add/bill/",
    GET_BILL: "api/v2/patient/get/bill/",
    UPDATE_BILL: "api/v2/patient/update/bill/",
    //clinical examination
    UPDATE_PATIENT_CLINICALEXAMINATION: "api/v2/patient/update/clinical/",
    // Deseases
    GET_DESEASES: "/api/v2/deseases/",
    ADD_DESEASES: "/api/v2/deseases/add",
    DELETE_DESEASES: "/api/v2/deseases/delete/",
    // Medicines
    GET_MEDICINES: "/api/v2/medicines",
    ADD_MEDICINES: "/api/v2/medicines/add",
    DELETE_MEDICINES: "/api/v2/medicines/delete",
    EDIT_MEDICINES: "/api/v2/medicines/edit",
    // Medicines Groups
    GET_MEDICINE_GROUP: "/api/v2/medicines/get-all-group",
    ADD_MEDICINE_GROUP: "/api/v2/medicines/add-group",
    DELETE_MEDICINE_GROUP: "/api/v2/medicines/group/delete/",
    // Prescription
    GET_PRESCRIPTIONS: "/api/v2/prescriptions/",
    ADD_PRESCRIPTION: "/api/v2/prescriptions/add",
    DELETE_PRESCRIPTION: "api/v2/prescriptions/delete",
    UPDATE_PRESCRIPTION: "api/v2/prescriptions/update",
    GET_DOCTORS: "/api/v2/user/",
    //    Examination
    GET_ALL_EXAMINATION: "/api/v2/examinations",// /:examinationId
    ADD_EXAMINATION: "/api/v2/examinations/add", // /:patientId
    DELETE_EXAMINATION: "/api/v2/examinations/delete",// /:patientId/:examinationId
    EDIT_EXAMINATION: "/api/v2/examinations/edit",// /:patientId/:examinationId
    // treatment
    GET_TREATMENT: "/api/v2/treatment",
    ADD_TREATMENT: "/api/v2/treatment/add",
    DELETE_TREATMENT: "/api/v2/treatment/delete",
    EDIT_TREATMENT: "/api/v2/treatment/edit",
    // procedure
    GET_PROCEDURE: "/api/v2/procedure/",// :patientId
    ADD_PROCEDURE: "/api/v2/procedure/add/", // :patientId
    DELETE_PROCEDURE: "/api/v2/procedure/delete",
    EDIT_PROCEDURE: "/api/v2/procedure/edit",

    // report patient
    DELETE_PATIENT_REPORT: "/api/v2/patient/delete/report/",
    // fee
    GET_FEE: "/api/v2/fee/",
    ADD_FEE: "/api/v2/fee/add",
    DELETE_FEE: "/api/v2/fee/delete/",// :id
    // lab 
    GET_LABS: "/api/v2/lab",
    ADD_LAB: "/api/v2/lab/addlab",
    DELETE_LAB: "/api/v2/lab/deactivate-lab/",// :lab id
    GET_LAB_DATA: "/api/v2/lab/lab-data",
    GET_LAB_DATA_BY_PATIENT_ID: "/api/v2/lab/lab-data/",// :patient Id
    ADD_LAB_DATA: "/api/v2/lab/add-lab-data/", // :patient Id
    DELETE_LAB_DATA: "/api/v2/lab/delete-lab-data/", // :lab data id
    LAB_WORK_DONE: "/api/v2/lab/work-done/", // :lab data id
    // materials
    GET_MATERIAL: "/api/v2/material",
    ADD_MATERIAL: "/api/v2/material/add",
    DELETE_MATERIAL: "/api/v2/material/delete/",// :id 
    EDIT_MATERIAL: "/api/v2/material/edit/",// :id,
    // to do list
    ADD_TASK: "/api/v2/to-do-list/add/",
    DELETE_TASK: "/api/v2/to-do-list/delete/", // id
    UPDATE_TASK: "/api/v2/to-do-list/update/", // id
    GET_ALL_TASK: "/api/v2/to-do-list/?",
    // ortho section
    ADD_ORTHO: "/api/v2/ortho/add-ortho/",
    DELETE_ORTHO: "/api/v2/ortho/delete/", // id
    UPDATE_ORTHO: "/api/v2/ortho/edit", // patientId,orthoId
    GET_ALL_ORTHO: "/api/v2/ortho",
    GET_ALL_ORTHO_BY_PATIENT_ID: "/api/v2/ortho/", // id
    // employee section
    ADD_EMPLOYEE: "/api/v2/employees/add",
    GET_ALL_EMPLOYEE: "/api/v2/employees",
    DELETE_EMPLOYEE: "/api/v2/employees/delete/", // id
    ACCESS_DENIED_EMPLOYEE: "/api/v2/employees/access-denied/", // id
    ACCESS_EMPLOYEE: "/api/v2/employees/access/", // id
    UPDATE_EMPLOYEE : ""
}