import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { addOrtho, getAllOrtho } from '../../redux/ortho section/orthoApi'
import { ErrorMessage } from '@hookform/error-message'
import SquareButton from '../../components/Ui Components/SquareButton'
import { FaClipboardList } from 'react-icons/fa'
import { selectAllPatient } from '../../redux/patientAppointments/patientSlice';
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io'
import { paginationHelper } from '../../utils/helper'

export default function Orthosection() {

    const allPatient = useSelector(selectAllPatient)
    const [isLoading, setIsLoading] = useState(false)
    const [orthoData, setOrthoData] = useState([])
    console.log("Patients:-",orthoData)

    const [page, setPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [selectedPage, setSelectedPage] = useState(1)
    const arrLength = paginationHelper(orthoData?.length, itemsPerPage)
    // console.log(arrLength)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    // add Ortho
    async function handleAddLab(data) {
        setIsLoading(true)
        try {
            let res = await addOrtho(data)
            if (res.status) {
                toast.success("Added")
                setOrthoData([...orthoData, res.data])
                reset()
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
    }

    // for task Complite 
    // async function handleTaskDone(taskId) {
    //     const is = window.confirm("Are You Sure ?");
    //     toast.loading("Loading...")
    //     try {
    //         if (is) {
    //             let response = await updateTask(taskId)
    //             if (response.status) {
    //                 toast.dismiss()
    //                 let arr = toDoList.filter(item => item._id !== response.data._id)
    //                 setToDoList([...arr, response.data])
    //                 toast.success("Complited Sucessfully")
    //             } else {
    //                 toast.dismiss()
    //             }
    //         }
    //     } catch (error) {
    //         console.log(error);
    //         toast.dismiss()
    //     }
    // }

    let getOrtho = useCallback(async () => {
        try {
            let list = await getAllOrtho()
            if (list.status) {
                setOrthoData(list.data)
            } else {
                setOrthoData([])
            }
        } catch (error) {
            console.log(error);
        }

    }, [])

    useEffect(() => {
        getOrtho()
    }, [])

    // filter functionality start
    // clear filter
    function clearFilter() {

    }

    // for filter by date
    const handleFilter = (e) => {
        e.preventDefault()

    };

    function handleTotal() {
        const totalAmount = orthoData.reduce((total, patient) => {
            // console.log("RUNNING...", patient?.total_amount);
            return total + parseInt(patient?.total_amount || 0); 
        }, 0); 
        // console.log("Total Amount:", totalAmount);
        return totalAmount
    }

    function handleDeposits() {
        const totalAmount = orthoData.reduce((total, patient) => {
            // console.log("RUNNING...", patient?.total_amount);
            return total + parseInt(patient?.deposite || 0); 
        }, 0); 
        // console.log("Total Amount:", totalAmount);
        return totalAmount
    }

    function handleRemaining() {
        const totalAmount = orthoData.reduce((total, patient) => {
            // console.log("RUNNING...", patient?.total_amount);
            return total + parseInt(patient?.less || 0); 
        }, 0); 
        console.log("Total Amount:", totalAmount);

        const remaining = handleTotal() - handleDeposits()
console.log(remaining)

        return totalAmount
    }
    

    return (
        <>
            <div className="flex flex-col md:flex-row w-full p-1">
                {/* First Box - One Third Width */}
                <div className="w-full md:w-1/3 p-1 rounded-lg md:mb-0">
                    <div id="uploadfile-form-section" className="rounded bg-white border-2 pb-3" >
                        <h1 className="text-sm py-2 text-center  bg-gray-300 text-[#301762]">
                            Ortho Form
                        </h1>
                        <div className="max-w-md mx-auto p-6 bg-white rounded-lg">
                            <form onSubmit={handleSubmit(handleAddLab)}>
                                <div className="mb-2">
                                    <label htmlFor="patient" className="block text-sm font-medium leading-6 text-gray-600">
                                        Patient File Number
                                    </label>
                                    <input
                                        id="patient"
                                        name="patient"
                                        type='text'
                                        autoComplete="patient"
                                        {...register("patient", {
                                            required: "*patient is required",
                                        })}
                                        placeholder='Name | File Number'
                                        list='plist'
                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                    />
                                    <datalist id='plist'>
                                        {allPatient && allPatient.map((ele, ind) => (
                                            <option value={ele.file_number} key={ind}>{`${ele.patient_name} ${ele.surname} ${ele.file_number}`}</option>
                                        ))}
                                    </datalist>
                                    <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                                        <ErrorMessage errors={errors} name="patient" />
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="work_done" className="block text-sm text-gray-600 font-medium mb-2">
                                        Work Done
                                    </label>
                                    <input
                                        name="work_done"
                                        id="work_done"
                                        type='text'
                                        {...register("work_done")}
                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                    />
                                    <div className="text-red-600 text-xs">
                                        <ErrorMessage errors={errors} name="work_done" />
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="breakage" className="block text-sm text-gray-600 font-medium mb-2">
                                        Breakage
                                    </label>
                                    <input
                                        name="breakage"
                                        id="breakage"
                                        type='text'
                                        {...register("breakage")}
                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                    />
                                    <div className="text-red-600 text-xs">
                                        <ErrorMessage errors={errors} name="breakage" />
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="breakage_amount" className="block text-sm text-gray-600 font-medium mb-2">
                                        Breakage Amount
                                    </label>
                                    <input
                                        name="breakage_amount"
                                        id="breakage_amount"
                                        type='text'
                                        {...register("breakage_amount", {
                                            pattern: /^[0-9]*$/
                                        })}
                                        defaultValue={0}
                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                    />
                                    <div className="text-red-600 text-xs">
                                        {errors.breakage_amount && <p>*Please enter number.</p>}
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="total_amount" className="block text-sm text-gray-600 font-medium mb-2">
                                        Total Amount
                                    </label>
                                    <input
                                        name="total_amount"
                                        id="total_amount"
                                        type='number'
                                        {...register("total_amount", {
                                            pattern: /^[0-9]*$/
                                        })}
                                        defaultValue={0}
                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                    />
                                    <div className="text-red-600 text-xs">
                                        {errors.total_amount && <p>*Please enter number</p>}
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="deposite" className="block text-sm text-gray-600 font-medium mb-2">
                                        Deposite
                                    </label>
                                    <input
                                        name="deposite"
                                        id="deposite"
                                        type='text'
                                        {...register("deposite", {
                                            pattern: /^[0-9]*$/
                                        })}
                                        defaultValue={0}
                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                    />
                                    <div className="text-red-600 text-xs">
                                        {errors.deposite && <p>*Please enter number</p>}
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="less" className="block text-sm text-gray-600 font-medium mb-2">
                                        Less
                                    </label>
                                    <input
                                        name="less"
                                        id="less"
                                        type='number'
                                        {...register("less", {
                                            pattern: /^[0-9]*$/
                                        })}
                                        defaultValue={0}
                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                    />
                                    <div className="text-red-600 text-xs">
                                        {errors.less && <p>*Please enter number</p>}
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="braces" className="block text-sm text-gray-600 font-medium mb-2">
                                        Braces
                                    </label>
                                    <input
                                        name="braces"
                                        type='text'
                                        id="braces"
                                        {...register("braces")}
                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                    />
                                    <div className="text-red-600 text-xs">
                                        <ErrorMessage errors={errors} name="braces" />
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="send_date" className="block text-sm text-gray-600 font-medium mb-2">
                                        Date
                                    </label>
                                    <input
                                        name="send_date"
                                        id="send_date"
                                        type='date'
                                        {...register("send_date")}
                                        className="w-full p-2 border text-sm border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-[#301762]"
                                    />
                                    <div className="text-red-600 text-xs">
                                        <ErrorMessage errors={errors} name="send_date" />
                                    </div>
                                </div>
                                <SquareButton
                                    isLoading={isLoading}
                                    sqicon={<span className='text-xs'>Add Ortho</span>}
                                    className={"w-full bg-[#301762] text-white p-2 rounded focus:outline-none focus:ring-2 focus:ring-[#301762]"}
                                    btnType={"submit"}
                                />
                            </form>
                        </div>
                    </div>
                </div>

                {/* Second Box - Two Thirds Width */}
                <div className="w-full md:w-2/3 p-1 rounded-lg">
                    <section id="medicine-section" className="rounded-sm border-2 overflow-y-auto w-full">
                        <div className="flex bg-gray-300 justify-between pl-2 p-2 rounded-t-sm text-white items-center sticky top-0">
                            <div className='flex items-center'>
                                <FaClipboardList className="text-2xl p-0 m-0" />
                                <h1 className="pl-3 text-sm py-2 text-[#301762]">
                                    Ortho Patients ({orthoData?.length})
                                </h1>
                            </div>
                            <div className='flex gap-3'>
                                {/* <form action="" className="flex gap-2" onSubmit={handleFilter}>
                                    <input
                                        className="p-2 text-sm rounded-sm text-gray-600"
                                        type="date"
                                        defaultValue={orthoData.split("-").reverse().join("-")}
                                        onChange={(e) => { setTodayDate(DateFormat(e.target.value)) }}
                                    />
                                    <SquareButton
                                        btnType={"submit"}
                                        sqicon={<span className="text-xs">Search</span>}
                                    />
                                    <SquareButton
                                        btnType={"reset"}
                                        handleClick={clearFilter}
                                        // disable={DateFormat(Date.now()) === todayDate}
                                        sqicon={<span className="text-xs">Today List</span>}
                                    />
                                </form> */}
                            </div>
                        </div>
                        {/* //======================file display table section start============================= */}
                        {orthoData.length > 0 ? <div className="overflow-x-auto">
                            <table className="min-w-full bg-white rounded-lg">
                                <thead>
                                    <tr className="bg-gray-100 text-center text-sm text-gray-600">
                                        <th className="py-2 px-2 font-medium ">Sr No</th>
                                        <th className="py-2 px-2 font-medium">Patient</th>
                                        <th className="py-2 px-2 font-medium">Work Done</th>
                                        <th className="py-2 px-2 font-medium">Total Amount</th>
                                        <th className="py-2 px-2 font-medium">Deposit</th>
                                        <th className="py-2 px-2 font-medium">Remaining</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orthoData && orthoData?.slice((page * itemsPerPage) - itemsPerPage, page * itemsPerPage).map((item, idx) => (
                                        <tr className="border-t border-gray-200 hover:bg-gray-50 text-gray-700 text-center text-xs" key={idx}>
                                            <td className="py-2 px-2 ">{(page * itemsPerPage + idx + 1 - 10)}</td>
                                            <td className="py-2 px-4">
                                                <Link to={`/patient/patientdetails/${item?.patient?._id}`} className='hover:text-blue-700'>
                                                    {<>{item.patient?.patient_name}<br />
                                                        <>F-{item.patient?.file_number}</>
                                                    </>}
                                                </Link>
                                            </td>
                                            <td className="py-2 px-4">{item.work_done}</td>
                                            <td className="py-2 px-4">{item.total_amount}₹</td>
                                            <td className="py-2 px-4">{item.deposite}₹</td>
                                            <td className="py-2 px-4">{item.less}₹</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="|| PAGINATION || flex flex-row items-center justify-center my-2 gap-5 bg-[#eff6ff]">

                            <button className="flex flex-row items-center justify-center" onClick={() => {
                                if (page > 1) {
                                    setPage(page - 1)
                                    setSelectedPage(page - 1)
                                } else {
                                    setPage(1)
                                }
                            }
                            }>
                                <IoMdArrowBack className=" cursor-pointer font-extrabold hover:scale-150 duration-300" />
                                <span>Previous</span>
                            </button>


                            {arrLength?.map((item, index) => (
                                <span className={`${selectedPage === index + 1 ? "bg-[#d2c2f1]" : ""} rounded-full py-1 px-3 cursor-pointer hover:scale-75 duration-75`} onClick={() => { setPage(index + 1); setSelectedPage(index + 1) }} >{item}</span>
                            ))}

                            <button className="flex flex-row items-center justify-center" onClick={() => {
                                if (page < arrLength.length) {
                                    setPage(page + 1)
                                    setSelectedPage(page + 1)
                                } else {
                                    setPage(1)
                                    setSelectedPage(1)
                                }
                            }
                            }>
                                <span>Next</span>
                                <IoMdArrowForward className=" cursor-pointer font-extrabold hover:scale-150 duration-300 " />
                            </button>

                        </div>


                        </div> :
                            <div className='flex justify-center'>
                                <h2 className='mt-4 mb-4'>No Data</h2>
                            </div>
                        }
                    </section>
                    <section className="rounded-sm border-2 overflow-y-auto w-full p-4 flex flex-col items-start justify-start">
                        <h2 className="text-lg font-bold self-center py-1">Collection Report</h2>
                        <ul>
                            <li><span>Total Amount: </span> ₹ {handleTotal()}</li>
                            <li>Total Deposited: <span className='font-semibold text-green-500'> ₹ {handleDeposits()}</span></li>
                            <li>Total Remaining: <span className='font-semibold text-red-500'> ₹ {handleRemaining()}</span></li>
                        </ul>
                        </section>             
                </div >
            </div >
        </>
    )
}
