import { useState } from "react";
import { FaLock, FaUser } from "react-icons/fa";
import SquareButton from "../../components/Ui Components/SquareButton";

const ForgotPassForm = () => {
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [buttonValue, setButtonValue] = useState("Reset Password")
    const [showOtp, setShowOtp] = useState(false)


    const handleReset = (e) => {
        e.preventDefault();
        if (email !== "") {
            setShowOtp(true)
            setButtonValue("Verify OTP")
        }

    }

    return <>
        <div>
            <div id="header-top" className="flex justify-around h-full mt-6">
                <img src="/asset/images/LOGO.png" alt="logo" />
                <img src={`/asset/images/balaji.png`} style={{ height: "200px" }} alt="logo-side" />
            </div>
        </div>
        <div className="bg-[#301762] h-4 w-full"></div>
        <div id="form-container">
            <div className="grid grid-cols-1 sm:grid-cols-2 w-full">
                <div id="form-sidebox" className="sm:p-0 p-6 sm:rounded-none rounded-se-3xl">

                </div>

                <div
                    id="form-box"
                    className="doctor-login-form h-auto bg-white m-auto py-3 px-6 rounded-ee-3xl sm:rounded-e-3xl">
                    <img
                        src={`/asset/icons/Blue Matte Simple Dentist Logo.png`}
                        alt="reception"
                        className="w-14 mx-auto h-auto"
                    />
                    <h1 className="text-center font-bold text-[#301762] font-serif text-3xl ">
                        Hello User!
                    </h1>
                    <h6 className="text-center">Let's help you reset your Password</h6>
                    <div>
                        <form
                            id="login-form"
                            className="my-4 flex justify-center px-12 flex-col"
                            onSubmit={handleReset}
                        >
                            <div className="flex flex-col gap-3">
                                <div className="input-container  flex items-center gap-2 py-2 px-2">
                                    <FaUser />
                                    <input
                                        className=" focus:outline-none outline-none w-full "
                                        type="email"
                                        id="email"
                                        autoComplete="false"
                                        placeholder="Enter your email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                {/* otp */}
                                <div className={`${showOtp ? "" : "hidden"} input-container flex items-center gap-2 p-2`}>
                                    <FaLock />
                                    <input
                                        className=" focus:outline-none outline-none w-full "
                                        type="number"
                                        id="otp"
                                        min={100000}
                                        max={999999}
                                        placeholder="Enter OTP"
                                        onChange={(e) => setOtp(e.target.value)}
                                        required={true}
                                    />
                                </div>
                            </div>
                            {/* {isLoading ? <div className='loader ml-6'></div> : <button
                        onClick={handleLogin}
                        className="bg-[#301762] text-white hover:bg-[#3b2d57] text-xl py-1 px-3 rounded-lg my-6"
                      >
                        Login
                      </button>} */}
                            <SquareButton
                                btnType={"submit"}
                                sqicon={<span className="text-sm w-full">{buttonValue}</span>}
                                isLoading={isLoading}
                                className={"mt-7 bg-[#301762]"}
                            />
                        </form>
                    </div>
                </div>

            </div>
        </div>
        <footer className="bg-[#301762] py-4 flex justify-center">
            <div className="flex flex-col justify-center items-center">
                <h6 className="font-center text-white font-medium">
                    ©All copy rights reserved by Shree Balaji Dental Clinic
                </h6>
                <h6 className="font-center text-white font-semibold">2023-2024</h6>
            </div>
        </footer>
    </>

}

export default ForgotPassForm;