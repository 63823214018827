import { FaUser } from "react-icons/fa";
import { useForm } from "react-hook-form";

const UserProfileForm = ({ userDetails }) => {
  const { handleSubmit, register } = useForm()
  // console.log(userDetails)


  const profileEdit = (data) => {
    // console.log(data)
  }

  return <>
    <div className="p-3 border-2 rounded">
      <div id="heading" className="flex items-center justify-center gap-2 font-semibold text-gray-900 leading-8">
        <span className="text-[#301762]">
          <FaUser />
        </span>
        <span className="tracking-wide">About</span>
      </div>

      <form className="grid md:grid-cols-1 gap-1 text-gray-700" 
        onSubmit={handleSubmit(profileEdit)}  >
        <div className="grid grid-cols-2">
          <div className="p-2 font-semibold flex items-center justify-start">Full Name :-</div>
          <div className="p-2">
            <input className="outline-2 border-2 p-2 rounded"
              {...register("name")}
              defaultValue={userDetails.name}
            />
          </div>
        </div>
        <div className="grid grid-cols-2">
          <div className="p-2 font-semibold flex items-center justify-start">Role :-</div>
          <div className="p-2">{userDetails.role === 0 ? "Receptionist" : "Doctor"}</div>
        </div>
        <div className="grid grid-cols-2">
          <div className="p-2 font-semibold flex items-center justify-start">Contact No :-</div>
          <div className="p-2">+91
            <input className="outline-2 border-2 p-2 rounded" type="number" {...register('mobile')} defaultValue={userDetails?.mobile} /></div>
        </div>
        <div className="grid grid-cols-2">
          <div className="p-2 font-semibold flex items-center justify-start">Email :-</div>
          <div className="p-2 lowercase">
            <a
              className="text-blue-800"
              href={`mailto:${userDetails.email}`}
            >
              {userDetails.email}
            </a>
          </div>
        </div>
        <div className="grid grid-cols-2">
          <div className="p-2 font-semibold flex items-center justify-start">Gender :-</div>
          <div className="p-2">{userDetails.gender}</div>
        </div>
        <div className="grid grid-cols-2">
          <div className="p-2 font-semibold flex items-center justify-start">Age :-</div>
          <div className="p-2">
            <input className="outline-2 border-2 p-2 rounded" {...register("age")}
              defaultValue={userDetails.age} type="number" min={18} />
            <span className="text-sm">Years</span></div>
        </div>
        <div className="grid grid-cols-2">
          <div className="p-2 font-semibold flex items-center justify-start">
            Permanent Address :-
          </div>
          <div className="p-2">
            <input className="outline-2 border-2 p-2 rounded" {...register("address")}
              defaultValue={userDetails?.address} />
          </div>
        </div>
        <button type="submit" className="bg-[#301762] text-white text-lg cursor-pointer hover:text-[#301762] hover:bg-gray-200 duration-300 p-1 my-1 rounded-lg">Submit</button>
      </form>
    </div>
  </>
}

export default UserProfileForm;