import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { FaFileInvoiceDollar, FaPrint, FaRegCircleUser } from "react-icons/fa6";
import { useForm } from "react-hook-form";
import SquareButton from "../Ui Components/SquareButton";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewBillByPatientIdAsync,
  selectedPatient,
  selectedPatientBill,
} from "../../redux/patientAppointments/patientSlice";
import moment from "moment";
import toast from "react-hot-toast";
import { Prifix } from "../../utils/helper";
import { selectFee } from "../../redux/fee/feeSlice";
import { selectTreatment } from "../../redux/treatment/treatmentSlice";
import { FaEye } from "react-icons/fa";
import InvoiceTemplate from "../invoices/InvoiceTemplate";
import PrescriptionEdilModel from "../modal/prescriptionEditModel";

export default function Bill() {
  const { id } = useParams();
  const allTreatment = useSelector(selectTreatment);
  const fees = useSelector(selectFee)
  const activeFee = fees && fees.find(item => item.isDelete === false)
  const [totalPaid, setTotalPaid] = useState(0)
  const [paymentMode, setPaymentMode] = useState("Cash")
  const [filterProcedure, setFilterProcedure] = useState([])
  const [open, setOpen] = useState(false);
  const [modelData, setModelData] = useState()
  const paymentRef = useRef();
  const paymentModeRef = useRef();
  const dispatch = useDispatch();
  const patientDetails = useSelector(selectedPatient)
  const getAllBill = useSelector(selectedPatientBill);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  // console.log(getAllBill)


  // Function to open modal and update bill
  const openModal = (data) => {
    setOpen(true);
    setModelData(data);
  };

  // model close dependency
  useEffect(() => {
    if (!open) {
      setModelData();
    }
  }, [open])

  // add bill function
  const onSubmit = async (data) => {
    data.bill_no = `SBDC-${patientDetails?.file_number}-${getAllBill?.length + 1}`
    try {
      await toast.promise(
        dispatch(addNewBillByPatientIdAsync({ patientId: id, billDetails: data })),
        {
          loading: "Saving...",
          success: "Bill Generated Successfully",
          error: "Please try again",
        }
      )
      reset()
    } catch (error) {
      toast.error(error.message)
    }


  };

  function changeTreatment(e) {
    const { value } = e.target;
    setFilterProcedure(allTreatment && allTreatment.filter(item => item._id === value))
  }

  // const handleUpdateBill = (e) => {
  //   e.preventDefault();
  //   axiosClient()
  //     .patch(`${webURLs.UPDATE_BILL}${id}`, {
  //       payment: paymentRef.current.value,
  //       mode_of_payment: paymentModeRef.current.value,
  //     })
  //     .then((res) =>
  //       toast.success(`payment of ${res.payload.payment} done successfully`)
  //     )
  //     .catch((error) => console.log(error.message));
  // };

  return (
    <>
      <div className="mb-2">
        <div className="flex justify-between w-full p-3">
          <div className="flex">
            <span>
              <FaRegCircleUser className="text-2xl pt-2 pl-2 " />
            </span>
            <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
              Patient Name -
            </p>
            <p className="text-gray-600 text-sm pt-[5px] pl-2">
              {`${Prifix(patientDetails)} ${patientDetails?.patient_name} ${patientDetails?.surname}`}
            </p>
          </div>
          <div className="flex mr-2">
            <p className="text-black from-slate-500 text-sm pt-[5px] pl-2">
              File Number -
            </p>
            <p className="text-gray-600 text-sm pt-[5px] pl-2">
              {patientDetails?.file_number}
            </p>
          </div>
        </div>
        <div className="bg-white rounded border-t-2 border-b-2">
          <div className="flex items-center bg-[#301762] rounded-t-sm justify-center space-x-2 text-white text-sm px-4 py-2">
            <span className="tracking-wide font-medium">Bill Form</span>
          </div>
          <hr />
          <form className="py-8" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full m-4 px-4 flex flex-wrap gap-8">
              <div>
                <label
                  htmlFor="bill_no"
                  className="block text-sm font-medium text-gray-600"
                >
                  Bill Number
                </label>
                <div className="mt-2">
                  <input
                    id="bill_no"
                    type="text"
                    defaultValue={`SBDC-${patientDetails?.file_number}-${getAllBill?.length + 1}`}
                    autoComplete="bill_no"
                    readOnly
                    className=" rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="consultation_fee"
                  className="block text-sm font-medium text-gray-600"
                >
                  Consultation fee
                </label>
                <div className="mt-2">
                  <input
                    id="consultation_fee"
                    {...register("consultation_fee", {
                      required: "*Fee is required",
                    })}
                    defaultValue={activeFee?.consultation}
                    name="consultation_fee"
                    type="number"
                    className="rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                  />
                  <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                    <ErrorMessage errors={errors} name="consultation_fee" />
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="x_ray"
                  className="block text-sm font-medium text-gray-600"
                >
                  X-ray fee
                </label>
                <div className="mt-2">
                  <input
                    id="x_ray_fee"
                    {...register("x_ray_fee", {
                      required: "*x-ray fee is required",
                    })}
                    defaultValue={activeFee?.x_ray}
                    name="x_ray_fee"
                    type="number"
                    autoComplete="x_ray_fee"
                    className="rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                  />
                  <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                    <ErrorMessage errors={errors} name="x_ray_fee" />
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="other_fee"
                  className="block text-sm font-medium text-gray-600"
                >
                  Other Fee
                </label>
                <div className="mt-2">
                  <input
                    id="other_fee"
                    {...register("other_fee")}
                    name="other_fee"
                    defaultValue={activeFee?.other_fee}
                    type="number"
                    autoComplete="other_fee"
                    className="rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="estimate_fee"
                  className="block text-sm font-medium text-gray-600"
                >
                  Treatment Taken
                </label>
                <div className="mt-2">
                  <select
                    id="treatment"
                    name="treatment"
                    {...register("treatment", {
                      required: "*treatment_taken is required",
                    })}
                    onChange={changeTreatment}
                    // autoComplete="treatment"
                    className="rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                  >
                    <option value="">-Select-</option>
                    {allTreatment && allTreatment.map((item, indx) => (
                      <option value={item._id} key={indx}>{item.treatment_name}</option>
                    ))}
                  </select>
                  <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                    <ErrorMessage errors={errors} name="treatment" />
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="procedure_name"
                  className="block text-sm font-medium text-gray-600"
                >
                  Procedure
                </label>
                <div className="mt-2">
                  <select
                    id="procedure_name"
                    name="procedure_name"
                    {...register("procedure_name")}
                    className="rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                  >
                    <option value="">-Select-</option>
                    {filterProcedure && filterProcedure[0]?.phase?.map((ele, idx) => (
                      <option value={ele.procedure_name} key={idx}>{ele.procedure_name}</option>
                    ))}
                  </select>
                  <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                    <ErrorMessage errors={errors} name="procedure_name" />
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="procedure_fee"
                  className="block text-sm font-medium text-gray-600"
                >
                  Procedure Fee
                </label>
                <div className="mt-2">
                  <input
                    id="procedure_fee"
                    {...register("procedure_fee")}
                    name="procedure_fee"
                    type="number"
                    defaultValue={0}
                    autoComplete="procedure_fee"
                    className="rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                  />
                  <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                    <ErrorMessage errors={errors} name="procedure_fee" />
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="total_paid"
                  className="block text-sm font-medium text-gray-600"
                >
                  Paid Amount
                </label>
                <div className="mt-2">
                  <input
                    id="total_paid"
                    {...register("total_paid")}
                    name="total_paid"
                    defaultValue={totalPaid}
                    type="number"
                    onChange={(e) => { setTotalPaid(parseInt(e.target.value)) }}
                    autoComplete="total_paid"
                    className="rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="payment_mode"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Mode of Payment
                </label>
                <div className="mt-2">
                  <select
                    id="payment_mode"
                    name="payment_mode"
                    {...register("payment_mode", {
                      required: "*payment mode is required",
                    })}
                    onChange={(e) => { setPaymentMode(e.target.value) }}
                    className=" rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#9F78FF] outline-none sm:text-sm sm:leading-6"
                  >
                    <option value="Cash">Cash</option>
                    <option value="Online App">Online App</option>
                    <option value="Bank Transfer">Bank Transfer</option>
                    <option value="Credit Card">Credit Card</option>
                    <option value="Debit Card">Debit Card</option>
                  </select>
                  <div className="text-red-600 flex flex-row items-center gap-1 text-sm">
                    <ErrorMessage errors={errors} name="payment_mode" />
                  </div>
                </div>
              </div>
              {paymentMode !== "Cash" ? <div>
                <label
                  htmlFor="transaction_number"
                  className="block text-sm font-medium text-gray-600"
                >
                  Transaction Number
                </label>
                <div className="mt-2">
                  <input
                    id="transaction_number"
                    {...register("transaction_number", {
                      required: "*Transaction Number is required",
                    })}
                    name="transaction_number"
                    type="text"
                    autoComplete="transaction_number"
                    className="rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                  />
                </div>
              </div> : ""}
              <div>
                <label
                  htmlFor="remaining_fee"
                  className="block text-sm font-medium text-gray-600"
                >
                  Remaining Fee
                </label>
                <div className="mt-2">
                  <input
                    id="remaining_fee"
                    name="remaining_fee"
                    {...register("remaining_fee")}
                    type="number"
                    defaultValue={0}
                    readOnly
                    autoComplete="remaining_fee"
                    className="rounded-md border-0 py-1.5 px-2 text-gray-600 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#301477] outline-none sm:text-sm"
                  />
                  <div className="text-red-600 flex flex-row items-center gap-1 text-xs">
                    <ErrorMessage errors={errors} name="remaining_fee" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-start ps-8 pt-3">
              <SquareButton btnType={"submit"} sqicon={<span className="text-xs">Add Bill</span>} />
            </div>
          </form>
        </div>
        <div className="bg-white rounded overflow-y-auto mb-2">
          <div className="flex flex-row justify-between">
            <div className="p-2 text-lg font-medium flex flex-row gap-3">
              <div className=" text-white bg-[#301762] rounded-full text-center p-3">
                <FaFileInvoiceDollar />
              </div>
              <p className="text-black text-xl py-[10px]">Billing</p>
            </div>
          </div>
          <table className="w-[100%] text-sm text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr className="text-center">
                <th scope="col" className="px-2 py-2 font-medium">
                  Sr.No.
                </th>
                <th scope="col" className="px-2 py-2 font-medium">
                  Bill No.
                </th>
                <th scope="col" className="px-2 py-2 font-medium">
                  Billing Date
                </th>
                <th scope="col" className="px-2 py-2 font-medium">
                  Total Amount
                </th>
                <th scope="col" className="px-2 py-2 font-medium">
                  Paid Amount
                </th>
                <th scope="col" className="px-2 py-2 font-medium">
                  Remaining Amount
                </th>
                <th scope="col" className="px-2 py-2 font-medium">
                  Treatment Taken
                </th>
                <th scope="col" className="px-2 py-2 font-medium">
                  Procedure
                </th>
                <th scope="col" className="px-2 py-2 font-medium">
                  View/Download
                </th>
              </tr>
            </thead>
            <tbody>
              {getAllBill?.map((element, index) => (
                <tr
                  key={index}
                  className="odd:bg-white text-xs even:bg-gray-50 text-gray-700 text-center"
                >
                  <th scope="row" className="px-2 py-2 font-medium">
                    {index + 1}
                  </th>
                  <td className="px-2 py-2">{element?.bill_no}</td>
                  <td className="px-2 py-2">
                    {moment(element?.createdAt).format("Do MMM YYYY")}
                  </td>
                  <td className="px-2 py-2">
                    {element?.consultation_fee +
                      element?.other_fee +
                      element?.procedure_fee + element?.x_ray_fee}₹
                  </td>
                  <td className="px-2 py-2">{element?.total_paid}₹</td>
                  <td className="px-2 py-2">
                    {element?.consultation_fee +
                      element?.other_fee +
                      element?.procedure_fee +
                      element?.x_ray_fee -
                      element?.total_paid}₹
                  </td>
                  {/* <td className="px-6 py-4">{}</td> */}
                  <td className="px-2 py-2">{element?.treatment?.treatment_name}</td>
                  <td className="px-2 py-2">{element?.procedure_name}</td>
                  <td className="py-2 px-2">
                    <div className="flex gap-3 justify-center">
                      <button onClick={() => { openModal(element) }} className="text-blue hover:text-purple-900 text-lg" title="Delete Lab Data "><FaEye /></button>


                      {/* PRINT BUTTON NOT WORKING!!! */}
                      {/* <button 
                      // onClick={()=><Link to={'/printBill'} ><InvoiceTemplate Data={modelData} /></Link> }
                      // onClick={() => HandlePrint({printDiv: <InvoiceTemplate Data={modelData} />}) } 
                      className="text-blue hover:text-purple-900 text-lg" title="Delete Lab Data "><FaDownload /></button> */}



                      {/* DELETE BUTTON */}
                      {/* <button onClick={() => { handleDelete(item._id) }} className="text-red-500 hover:text-red-400 text-lg" title="Delete Lab Data "><FaTrash /></button> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* modal component page */}

      <PrescriptionEdilModel
        content={
          <InvoiceTemplate Data={modelData} />
        }
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
