import React, { useState } from "react";
import "./LoginForm.css";
import { FaUser, FaLock } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { axiosClient } from "../../webServices/WebURL";
import { webURLs } from "../../webServices/GateWay";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { userLogginAsync } from "../../redux/user/userSlice";
import SquareButton from "../Ui Components/SquareButton";
import { useForm } from "react-hook-form";

const LoginForm = () => {
  const dispatch = useDispatch();
  const [userLogginPannel, setUserLogginPannel] = useState(true);
  const [isLoading, setIsLoading] = useState(false)
  const Navigate = useNavigate();
  const { register,
    formState: { errors },
    handleSubmit, reset } = useForm()

  // Login API Integration
  const handleDoctorLogin = async (data) => {
    setIsLoading(true)
    try {

      let result = await axiosClient().post(webURLs.LOGIN_DOCTOR, data);

      if (result.data.status) {
        setIsLoading(false)
        Cookies.set("user", JSON.stringify(result.data.data));
        Cookies.set("userId", result.data.data._id);
        Cookies.set("token", result.data.data.token);
        toast.success("Login successfully");
        reset()
        // dispatch(userLogginAsync({ email: email, password: password }));
        Navigate("/");
      } else {
        toast.error(result.data.message);
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      toast.error("Network Error Please try again After SomeTime...");
    }

  }

  const handleReceptionLogin = async (data) => {
    try {
      setIsLoading(true)

      let result = await axiosClient().post(webURLs.LOGIN_RECEPTION, data);

      if (result.data.status) {
        setIsLoading(false)
        Cookies.set("user", JSON.stringify(result.data.data));
        Cookies.set("userId", result.data.data._id);
        Cookies.set("token", result.data.data.token);
        toast.success("Login successfully");
        reset()
        // dispatch(userLogginAsync({ email: email, password: password }));
        Navigate("/");
      } else {
        toast.error(result.data.message);
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      toast.error("Network Error Please try again After SomeTime...");
    }

  }

  return (
    <>
      <div>
        <div id="header-top" className="flex justify-around h-full mt-6">
          <img src="/asset/images/LOGO.png" alt="logo" />
          <img src={`/asset/images/balaji.png`} style={{ height: "200px" }} alt="logo-side" />
        </div>
      </div>
      <div className="bg-[#301762] h-4 w-full"></div>
      <div id="form-container">
        <div className="grid grid-cols-1 sm:grid-cols-2 w-full">
          <div id="form-sidebox" className="sm:p-0 p-6 sm:rounded-none rounded-se-3xl">
            <div className="flex flex-col flex-wrap-reverse">
              <div className={userLogginPannel ? "user-login-options active" : "user-login-options"}
                onClick={() => {
                  setUserLogginPannel(true);
                }}
              >
                <h1>Doctor Login</h1>
              </div>
              <div
                className={userLogginPannel ? "user-login-options" : "user-login-options active"}
                onClick={() => {
                  setUserLogginPannel(false);
                }}
              >
                <h1>Reception Login</h1>
              </div>
            </div>
          </div>
          {userLogginPannel ? (
            <div
              id="form-box"
              className="doctor-login-form h-auto bg-white m-auto py-3 px-6 rounded-ee-3xl sm:rounded-e-3xl"
            >
              <img
                src={`/asset/icons/Blue Matte Simple Dentist Logo.png`}
                alt="reception"
                className="w-14 mx-auto h-auto"
              />
              <h1 className="text-center font-bold text-[#301762] font-serif text-3xl ">
                Doctor Login
              </h1>
              <h6 className="text-center">please login, have a nice day.</h6>
              <div>
                <form
                  id="login-form"
                  className="my-4 flex justify-center px-8 flex-col"
                  onSubmit={handleSubmit(handleDoctorLogin)}
                >
                  <div className="flex flex-col gap-3">
                    <div>
                      <div className={`${errors?.email?.message ? 'input-error' : 'input-container'} flex items-center gap-2 py-1 px-2`}>
                        <FaUser />
                        <input
                          className="focus:outline-none outline-none w-full"
                          type="email"
                          id="email"
                          name="email"
                          {...register("email", { required: "email required !" })}
                          placeholder="Email/Username"
                        />
                      </div>
                      {errors.email && <p role="alert" className="p-0 text-red-600 text-sm">{errors?.email?.message}</p>}
                    </div>
                    <div>
                      <div className={`${errors?.password?.message ? 'input-error' : 'input-container'} flex items-center gap-2 py-1 px-2`}>
                        <FaLock />
                        <input
                          className=" focus:outline-none outline-none w-full "
                          type="password"
                          id="password"
                          name="password"
                          {...register("password", { required: "password required !" })}
                          placeholder="Enter Password"
                        />
                      </div>
                      {errors.password && <p role="alert" className="p-0 text-red-600 text-sm">{errors?.password?.message}</p>}
                    </div>
                  </div>
                  <Link to={'/forgot-password'}>
                    <h6 className="text-end mt-2 hover:text-[#3b2d57] duration-200 cursor-pointer font-medium">
                      Forgotten Password?
                    </h6>
                  </Link>
                  <SquareButton
                    btnType={"submit"}
                    sqicon={<span className="text-sm">Login</span>}
                    isLoading={isLoading}
                    className={"mt-2 bg-[#301762]"}
                  />
                </form>
              </div>
            </div>
          ) : (
            <div
              id="form-box"
              className="reception-login-form h-auto bg-white m-auto py-3 px-6 rounded-ee-3xl sm:rounded-e-3xl"
            >
              <img
                src={`/asset/icons/reception.png`}
                alt="reception"
                className="w-14 mx-auto h-auto"
              />
              <h1 className="text-center font-bold text-[#301762] font-serif text-3xl ">
                Reception Login
              </h1>
              <h6 className="text-center">please login, have a nice day.</h6>
              <div>
                <form
                  id="login-form"
                  className="my-4 flex justify-center px-8 flex-col"
                  onSubmit={handleSubmit(handleReceptionLogin)}
                >
                  <div className="flex flex-col gap-3">
                    <div>
                      <div className={`${errors?.email?.message ? 'input-error' : 'input-container'} flex items-center gap-2 py-1 px-2`}>
                        <FaUser />
                        <input
                          className=" focus:outline-none outline-none w-full "
                          type="email"
                          id="email"
                          name="email"
                          {...register("email", { required: "email required !" })}
                          autoComplete="false"
                          placeholder="Email/Username"
                        />
                      </div>
                      {errors.email && <p role="alert" className="p-0 text-red-600 text-sm">{errors?.email?.message}</p>}
                    </div>
                    <div>
                      <div className={`${errors?.password?.message ? 'input-error' : 'input-container'} flex items-center gap-2 py-1 px-2`}>
                        <FaLock />
                        <input
                          className=" focus:outline-none outline-none w-full "
                          type="password"
                          id="password"
                          name="password"
                          {...register("password", { required: "password required !" })}
                          placeholder="Enter Password"
                        />
                      </div>
                      {errors.password && <p role="alert" className="p-0 text-red-600 text-sm">{errors?.password?.message}</p>}
                    </div>
                  </div>
                  <SquareButton
                    btnType={"submit"}
                    sqicon={<span className="text-sm">Login</span>}
                    isLoading={isLoading}
                    className={"mt-4 bg-[#301762]"}
                  />
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
      <footer className="bg-[#301762] py-4 flex justify-center">
        <div className="flex flex-col justify-center items-center">
          <h6 className="font-center text-white font-medium">
            ©All copy rights reserved by Shree Balaji Dental Clinic
          </h6>
          <h6 className="font-center text-white font-semibold">2025</h6>
        </div>
      </footer>
    </>
  );
};
export default LoginForm;
