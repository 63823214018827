export const pathOrigin = () => {
  const origin = window.location.origin;
  return origin;
};

export function Prifix(data) {
  return data && data.gender === "Female"
    ? data.marital_status === "single"
      ? "Miss."
      : "Mrs."
    : "Mr.";
}

export function GetTime(time) {
  if (time) {
    let date = new Date(time);
    return date.getTime();
  } else {
    let date = new Date();
    return date.getTime();
  }
}

export function DateFormat(dt) {
  let date = new Date(dt);
  let day = date.getDate() >= 9 ? date.getDate() : `0${date.getDate()}`;
  let month =
    date.getMonth() >= 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  return `${day}-${month}-${date.getFullYear()}`;
}

export function TimeFormat(dt) {
  let date = new Date(dt);
  let time = `${date.getHours()}:${date.getMinutes()}`;

  var arr = time.split(":"); //splits the string
  var hours = Number(arr[0]);
  var minutes = Number(arr[1]);
  var AMPM = "";
  if (hours >= 12 && hours != 24) {
    //checks if time is after 12 pm and isn't midnight
    hours = hours - 12;
    AMPM = " pm";
  } else if (hours < 12) {
    //checks if time is before 12 pm
    AMPM = " am";
  }

  if (hours == 24) {
    //special case if it is midnight with 24
    hours = hours - 12;
    AMPM = " am";
  }

  if (hours == 0) {
    //special case if it is midnight with 0
    hours = hours + 12;
  }

  //converts the Numbers back to a string
  var sHours = hours.toString();
  var sMinutes = minutes.toString();
  if (hours < 10) {
    //checks if the hours is 2 places long and adds a 0 if true
    sHours = "0" + sHours;
  }
  if (minutes < 10) {
    //checks if the minutes is 2 places long and adds a 0 if true
    sMinutes = "0" + sMinutes;
  }

  var result = sHours + ":" + sMinutes + AMPM; //sets string back together
  return result;
}

// Function to convert Base64 to Blob
export function base64ToBlob(base64, mimeType) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
}

// Pagination helper
export function paginationHelper(dataLength, itemsPerPage) {
  var requiredPages = null;
  requiredPages = dataLength / itemsPerPage;

  var roundUpPages = Math.ceil(requiredPages);
  // console.log(roundUpPages);

  var arrLength = new Array();

  for (let i = 1; i < roundUpPages + 1; i++) {
    arrLength = [...arrLength, i];
  }
  // console.log("Pagination Helper", arrLength);
  return arrLength;
}
