import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addTreatment, deleteTreatment, getAllTreatment } from "./treatmentApi";
import toast from "react-hot-toast";

const initialState = {
    treatment: [],
    status: "idle",
};
//=============GET MEDICINE THUNK================
export const getAllTreatmentAsync = createAsyncThunk(
    "treatment/get_treatment)",
    async () => {
        const response = await getAllTreatment();
        if (response.status) {
            return response.data;
        } else {
            // toast.error(response.message);
            return false;
        }
    }
);

//============= ADD MEDICINES THUNK =============
export const addTreatmentAsync = createAsyncThunk(
    "treatment/add_treatment",
    async (medicineDetails) => {
        const response = await addTreatment(medicineDetails);
        if (response.status) {
            return response.data;
        } else {
            toast.error(response.message);
            return false;
        }
    }
);

//===============DELETE MEDICINE API ====================
export const deleteTreatmentAsync = createAsyncThunk(
    "treatment/delete_treatment",
    async (medicineId) => {
        let isDel = window.confirm("Are You Sure!");
        try {
            if (isDel) {
                const response = await deleteTreatment(medicineId);
                if (response.status) {
                    return response.data;
                } else {
                    toast.error(response.message);
                    return false;
                }
            }
        } catch (error) {
            console.log(error.message);
        }
    }
);

export const treatmentSlice = createSlice({
    name: "treatment",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllTreatmentAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getAllTreatmentAsync.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) {
                    state.treatment = action.payload;
                }
            })
            .addCase(addTreatmentAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addTreatmentAsync.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) {
                    state.treatment.push(action.payload);
                    toast.success("treatment added successfully");
                } else {
                    toast.error("Something went wrong .failed to add medicine");
                }
            })
            .addCase(deleteTreatmentAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteTreatmentAsync.fulfilled, (state, action) => {
                if (action.payload) {
                    const index = state.treatment.findIndex(
                        (treatment) => treatment._id === action.payload._id
                    );
                    state.treatment.splice(index, 1);
                    toast.success("treatment deleted successfully");
                }
            });
    },
});

export const selectTreatment = (state) => state.treatment.treatment;

export default treatmentSlice.reducer;
