import { webURLs } from "../../webServices/GateWay";
import { axiosClient } from "../../webServices/WebURL";

//=================== ADD NEW EMPLOYEE API =======================
export async function addEmployee(empData) {
    const response = await axiosClient().post(
        `${webURLs.ADD_EMPLOYEE}`,
        empData
    );
    return response.data;
}


//=================== GET ALL EMPLOYEE API =======================
export async function getAllEmployees() {
    const response = await axiosClient().get(webURLs.GET_ALL_EMPLOYEE);
    return response.data;
}


//========================= DELETE EMPLOYEE API ==================
export async function deleteEmployee(empId) {
    const response = await axiosClient().delete(
        `${webURLs.DELETE_EMPLOYEE}${empId}`
    );
    return response.data;
}


//========================= UPDATE EMPLOYEE API ==================
export async function updateEmployee(empId) {
    const response = await axiosClient().put(
        `${webURLs.UPDATE_EMPLOYEE}${empId}`
    );
    return response.data;
}


//========================= ACCESS DENIED API ==================
export async function accessDeniedEmployee(empId) {
    const response = await axiosClient().put(
        `${webURLs.ACCESS_DENIED_EMPLOYEE}${empId}`
    );
    return response.data;
}


//========================= ACCESS  API ==================
export async function accessEmployee(empId) {
    const response = await axiosClient().put(
        `${webURLs.ACCESS_EMPLOYEE}${empId}`
    );
    return response.data;
}
