import { Routes, Route } from "react-router-dom";
import "./App.css";
import Layout from "./components/layout/Layout";

// Pages Import
import * as PAGE_ROUTES from "./routes/PageRoute";

// Tailwind Import
import "tailwindcss/tailwind.css";

// Private Route
import PrivateRoute from "./routes/PrivateRoute";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
  }, [])

  return (
    <>
      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          <Route index element={<PAGE_ROUTES.DASHBOARD_PAGE />} />
          <Route
            path="/upcomming-appointment"
            element={<PAGE_ROUTES.APPOINTMENTS_PAGE />}
          />
          <Route path="/patient" element={<Layout />}>
            <Route index element={<PAGE_ROUTES.PATIENTS_PAGE />} />
            <Route
              path="patientdetails/:id"
              element={<PAGE_ROUTES.PATIENT_DETAILS />}
            />
          </Route>
          <Route path="/missed-appointments" element={<PAGE_ROUTES.FOLLOW_UP />} />
          <Route path="/lab-details" element={<Layout />} >
            <Route index element={<PAGE_ROUTES.LAB_DETAILS />} />
            <Route path=":id" element={<PAGE_ROUTES.EACH_LAB_DATA />} />
          </Route>
          <Route path="/to-do-list" element={<PAGE_ROUTES.TO_DO_LIST />} />
          <Route path="/profile" element={<PAGE_ROUTES.USER_PROFILE_PAGE />} />
          <Route path="/employees" element={<Layout />} >
            <Route index element={<PAGE_ROUTES.EMPLOYEE />} />
            {/* <Route path=":id" element={<PAGE_ROUTES.EACH_LAB_DATA />} /> */}
          </Route>
          <Route path="/setting" element={<Layout />}>
            <Route index element={<PAGE_ROUTES.SETTING_PAGE />} />
            <Route path="appointmenttiming" element={<PAGE_ROUTES.APPOINTMENT_TIMING_PAGE />} />
            <Route path="fee" element={<PAGE_ROUTES.FEE />} />
            <Route path="uploadfile" element={<PAGE_ROUTES.UPLOAD_FILE />} />
            <Route path="medicine" element={<PAGE_ROUTES.MEDICINE />} />
            <Route path="medicine-group" element={<PAGE_ROUTES.MEDICINE_GROUP />} />
            <Route path="treatment" element={<PAGE_ROUTES.TREATMENT />} />
            <Route path="material" element={<PAGE_ROUTES.MARERIALS />} />
          </Route>
          <Route path="/ortho-section" element={<PAGE_ROUTES.ORTHO_SECTION />} />
        </Route>
        <Route path="/forgot-password" element={<PAGE_ROUTES.FORGOT_PAGE />} />
        <Route path="/login" element={<PAGE_ROUTES.LOGIN_PAGE />} />
      </Routes>
      <Toaster position="top-center" reverseOrder={true} />
      {/* <AddNewPatientForm ModelOpenStatus={openModel} getModelOpenStatus={(value)=>SetOpenModel(value)} /> */}
    </>
  );
}

export default App;
